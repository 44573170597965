export const SiteConst = {
  FORGOT_ERROR_MESSAGE: 'An error has occurred. Please try again later.',
  FORGOT_SUCCESS_MESSAGE: 'An email will be sent to you with a link to temporarily login and reset your password.',
  JOIN_ERROR_MESSAGE: 'An error has occurred. Please try again later.',
  JOIN_SUCCESS_MESSAGE: 'Thanks for joining. Welcome to the site.',
  LOGIN_ERROR_MESSAGE: 'An error has occurred. Please try again later.',
  LOGIN_SUCCESS_MESSAGE: 'Welcome back!',
  RESET_ERROR_MESSAGE: 'An error has occurred. Please try again later.',
  RESET_SUCCESS_MESSAGE: 'Your password has been successfully reset. Please login with your new password.',
  PROFILE_ERROR_MESSAGE: 'An error has occurred. Please try again later.',
  PROFILE_EMAIL_SUCCESS_MESSAGE: 'Your email has been updated.',
  PROFILE_ERROR_MESSAGE: 'Your email has been updated.',
  PROFILE_SUCCESS_MESSAGE: 'Your account has been updated.',
  PROFILE_PASSWORD_SUCCESS_MESSAGE: 'Your password has been updated.',
  PROFILE_PASSWORD_ERROR_MESSAGE: 'An error has occurred. Please try again later.',
  PROFILE_AVATAR_MAX_FILE_SIZE: 5,
  PROFILE_AVATAR_UPLOAD_ERROR_MESSAGE: 'Max image size is 5MB. Please use a smaller image.',
  PROFILE_AVATAR_ERROR_MESSAGE: 'An error has occurred. Please try again later.',
  PROFILE_AVATAR_SUCCESS_MESSAGE: 'Your avatar has been updated.',
  PROFILE_AVATAR_REMOVE_ERROR_MESSAGE: 'An error has occurred. Please try again later.',
  PROFILE_AVATAR_REMOVE_SUCCESS_MESSAGE:  'Your avatar has been removed from the site.',

  CREATE_MESSAGE_SUCCESS_MESSAGE: 'Your message has been saved.',
  CREATE_MESSAGE_ERROR_MESSAGE: 'An error has occurred. Please try again later.',

  DELETE_MESSAGE_SUCCESS_MESSAGE: 'Your message has been deleted.',
  DELETE_MESSAGE_ERROR_MESSAGE: 'An error has occurred. Please try again later.',

  USER_ACTIVE_SUCCESS_MESSAGE: 'User active status has been updated.',
  USER_ACTIVE_ERROR_MESSAGE: 'An error has occurred. Please try again later.',

  USER_ROLES_SUCCESS_MESSAGE: 'User roles has been updated.',
  USER_ROLES_ERROR_MESSAGE: 'An error has occurred. Please try again later.',

  CONTACT_SUCCESS_MESSAGE: 'Thank you. Your message has been sent.',
  CONTACT_ERROR_MESSAGE: 'An error has occurred. Please try again later.',

  RECAPTCHA_SUCCESS_MESSAGE: 'Thank you. Your message has been sent.',
  RECAPTCHA_ERROR_MESSAGE: 'An error has occurred. Please try again later.',

  SNIPCART_SUBSCRIBE_LABEL: 'Subscribe to our Fossil newsletter and receive a special coupon.',
  SNIPCART_TERMS: 'By ordering online, you are entrusting Fossil Creek Nursery to choose the best product available for you. Cannot do returns if you do not like the plant chosen. Orders placed after 1 pm are not guaranteed a same-day pick-up due to ever-changing daily operations. We will confirm the exact pickup time via email or phone call.'
}
