import PropTypes from 'prop-types'
import { Linker } from '@/components/content/linker'
import Icon from '@/components/shared/react-icons-wrapper'
import { transformToUpperCase } from '@/utils/helpers'

ButtonIconCTA.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  icon: PropTypes.string,
  type: PropTypes.string.isRequired,
  style: PropTypes.string,
  size: PropTypes.string,
  classes: PropTypes.string,
  theme: PropTypes.string,
  fullWidth: PropTypes.any || false,
}

export function ButtonIconCTA(props: { title: string; url: string; icon: string; type: string; style: string; size: string; theme: string; fullWidth: Boolean; }) {

  const { title, url, icon, type, style, size, theme, fullWidth } = props

  function StyleClasses(style: string, theme: string) {
    if (style === 'primary') {
      return 'inline-flex items-center justify-center border border-transparent rounded-md shadow-sm bg-fossil-green text-white hover:bg-brand-2-500 transition'
    } else if (style === 'secondary') {
      return 'inline-flex items-center justify-center text-gray-900 border border-transparent rounded-md shadow-sm bg-white hover:bg-gray-50 hover:border-gray-400 transition'
    } else if (style === 'cta') {
      return 'inline-flex items-center justify-center border border-gray-200 rounded-md shadow-sm bg-white hover:bg-gray-50 hover:border-gray-400 transition'
    } else {
      return null
    }
  }

  function SizeClasses(size: string) {
    if (size === 'lg') {
      return 'font-medium text-base lg:text-lg pr-4 pl-6 py-3'
    } else if (size === 'sm') {
      return 'font-medium text-sm lg:text-base pl-2.5 pr-2 py-1.5'
    } else {
      return 'font-medium text-base lg:text-lg pr-3.5 pl-4 py-3'
    }
  }

  return (
    <p>
      <Linker url={url} classes={`${SizeClasses(size)}
      ${StyleClasses(style, theme)} ${fullWidth ? 'w-full' : 'w-auto'}`}
      >{title}
        {icon && <Icon name={transformToUpperCase(icon, ['-'])} size={'1.2em'} color={'gray-700'} aria-hidden="true" className="inline-block ml-1" />}
      </Linker>
    </p>
  )
}
