import PropTypes from 'prop-types'
import Icon from '@/components/shared/react-icons-wrapper'
import { Linker } from '@/components/content/linker'
import { CallToAction } from '@/components/content/call-to-action'
import { transformToUpperCase } from '@/utils/helpers'
import { Text } from '@/components/layout/content-components/serializers/text'

OptionItem.propTypes = {
  layout: PropTypes.string,
  optionListTitle: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.array,
  icon: PropTypes.any,
  pricePerMonth: PropTypes.number,
  pricePerYear: PropTypes.number,
  callToAction: PropTypes.any,
  optionItemText: PropTypes.any,
}

function TopMargin(type: string) {
  if (type === 'buttonIcon' || type === 'button') {
    return 'mt-4'
  } else {
    return 'mt-3'
  }
}

export function OptionItem(props: { layout: string; optionListTitle: string; title: string; text: any; icon: any; pricePerMonth: number; pricePerYear: number; callToAction: any; optionItemText: any; }) {

  const { layout, title, text, icon, pricePerYear, pricePerMonth, callToAction, optionItemText, optionListTitle } = props
  if (layout === 'columns') {
    return (
      <div className="flex flex-col bg-white rounded-lg shadow-xl ring-1 ring-black/10">
        <div className="p-6 sm:p-7">

          {title ? <h3 className="text-2xl font-semibold text-gray-900">{title}</h3> : null}

          {(pricePerMonth && pricePerYear) ?
            <div className="flex items-center mt-3 space-x-3">
              {pricePerMonth ? <p className="text-4xl font-bold tracking-tight text-brand-2-600">${pricePerMonth}</p> : null}
              <div>
                {pricePerMonth ? <p className="text-sm text-gray-700">per month</p> : null}
                {pricePerYear ? <p className="text-sm text-gray-500">Billed yearly (${pricePerYear})</p> : null}
              </div>
            </div> : null}

          {(pricePerMonth && !pricePerYear) ?
            <div className="flex items-baseline mt-3 space-x-2">
              <p className="text-4xl font-bold tracking-tight text-brand-2-600">${pricePerMonth}</p>
              <p className="text-sm text-gray-700">per month</p>
            </div> : null}

          {(!pricePerMonth && pricePerYear) ?
            <div className="flex items-baseline mt-3 space-x-2">
              <p className="text-4xl font-bold tracking-tight text-brand-2-600">${pricePerYear}</p>
              <p className="text-sm text-gray-700">per year</p>
            </div> : null}

          {/* Text */}
          {text && <div className="mt-6 prose-p:text-gray-500 prose-a:underline hover:prose-a:no-underline max-w-prose"><Text blocks={text} /></div>}

        </div>

        <div className="flex flex-col flex-1 p-2">
          <div className="flex flex-col justify-between flex-1 p-6 rounded-lg bg-gray-50 sm:p-7">

            {optionListTitle ? <h3 className="mb-4 text-sm font-medium text-gray-900">{optionListTitle}</h3> : null}

            <ul role="list" className="mt-4 mb-4 space-y-3">
              {optionItemText ? (<>
                {optionItemText.map(text =>
                  <li key={text?._key} className="flex items-start">

                    {/* Icon */}
                    {icon?.name && (<span className="inline-flex items-center justify-center text-brand-2-500">
                      <Icon name={transformToUpperCase(icon?.name, ['-'])} size={'2em'} aria-hidden="true" />
                    </span>)}
                    <span className="ml-3 text-gray-500">{text?.title}</span>
                  </li>
                )}
              </>) : null}
            </ul>

            {/* CTA */}
            {(callToAction && callToAction?.type) && <div className='mt-auto'><div className={`${TopMargin(callToAction?.type)}`}><CallToAction title={callToAction?.title} url={callToAction?.url} type={callToAction?.type} icon={callToAction?.icon?.name} style={`primary`} size={`lg`} theme={`light`} fullWidth /></div></div>}
          </div>

        </div>
      </div>
    )
  } else if (layout === 'simple-columns') {
    return (
      <div className="relative flex flex-col p-8 bg-white border border-gray-200 rounded-lg shadow-sm">
        <div className="flex-1">

          {title ? <h3 className="text-2xl font-semibold text-gray-900">{title}</h3> : null}

          {(pricePerMonth && pricePerYear) ?
            <div className="flex items-center mt-3 space-x-3">
              {pricePerMonth ? <p className="text-5xl font-bold tracking-tight">${pricePerMonth}</p> : null}
              <div>
                {pricePerMonth ? <p className="text-sm text-gray-700">per month</p> : null}
                {pricePerYear ? <p className="text-sm text-gray-500">Billed yearly (${pricePerYear})</p> : null}
              </div>
            </div> : null}

          {(pricePerMonth && !pricePerYear) ?
            <div className="flex items-baseline mt-3 space-x-2">
              <p className="text-5xl font-bold tracking-tight">${pricePerMonth}</p>
              <p className="text-sm text-gray-700">per month</p>
            </div> : null}

          {(!pricePerMonth && pricePerYear) ?
            <div className="flex items-baseline mt-3 space-x-2">
              <p className="text-5xl font-bold tracking-tight">${pricePerYear}</p>
              <p className="text-sm text-gray-700">per year</p>
            </div> : null}


          {text && <div className="mt-6 prose-p:text-gray-500 prose-a:underline hover:prose-a:no-underline max-w-prose"><Text blocks={text} /></div>}

          {optionListTitle ? <h3 className="mt-8 text-sm font-medium text-gray-900">{optionListTitle}</h3> : null}

          <ul role="list" className="mt-4 mb-4 space-y-3">
            {optionItemText ? (<>
              {optionItemText.map(text =>
                <li key={text?._key} className="flex items-start">

                  {/* Icon */}
                  {icon?.name && (<span className="inline-flex items-center justify-center text-brand-2-500">
                    <Icon name={transformToUpperCase(icon?.name, ['-'])} size={'2em'} aria-hidden="true" />
                  </span>)}
                  <span className="ml-3 text-gray-500">{text?.title}</span>
                </li>
              )}
            </>) : null}
          </ul>

        </div>

        {/* CTA */}
        {(callToAction && callToAction?.type) && <div className='mt-auto'><div className={`${TopMargin(callToAction?.type)}`}><CallToAction title={callToAction?.title} url={callToAction?.url} type={callToAction?.type} icon={callToAction?.icon?.name} style={`primary`} size={`lg`} theme={`light`} fullWidth /></div></div>}

      </div>
    )
  } else if (layout === 'stacked') {
    return (
      <div className="pb-8 md:pb-12 xl:grid xl:grid-cols-3 xl:gap-x-8">
        <div>
          {title ? <h3 className="text-2xl font-semibold text-gray-900">{title}</h3> : null}

          {(pricePerMonth && pricePerYear) ?
            <div className="flex items-center mt-3 space-x-3">
              {pricePerMonth ? <p className="text-4xl font-bold tracking-tight text-brand-2-600">${pricePerMonth}</p> : null}
              <div>
                {pricePerMonth ? <p className="text-sm text-gray-700">per month</p> : null}
                {pricePerYear ? <p className="text-sm text-gray-500">Billed yearly (${pricePerYear})</p> : null}
              </div>
            </div> : null}

          {(pricePerMonth && !pricePerYear) ?
            <div className="flex items-baseline mt-3 space-x-2">
              <p className="text-4xl font-bold tracking-tight text-brand-2-600">${pricePerMonth}</p>
              <p className="text-sm text-gray-700">per month</p>
            </div> : null}

          {(!pricePerMonth && pricePerYear) ?
            <div className="flex items-baseline mt-3 space-x-2">
              <p className="text-4xl font-bold tracking-tight text-brand-2-600">${pricePerYear}</p>
              <p className="text-sm text-gray-700">per year</p>
            </div> : null}


          {text && <div className="mt-6 prose-p:text-gray-500 prose-a:underline hover:prose-a:no-underline max-w-prose"><Text blocks={text} /></div>}

          {/* CTA */}
          {(callToAction && callToAction?.type) && <div className='mt-6'><div className={`${TopMargin(callToAction?.type)}`}><CallToAction title={callToAction?.title} url={callToAction?.url} type={callToAction?.type} icon={callToAction?.icon?.name} style={`primary`} size={`lg`} theme={`light`} fullWidth={false} /></div></div>}
        </div>
        <div className="col-span-2 mt-6">

          {optionListTitle ? <div className="flex items-center mb-6">
            <h4 className="flex-shrink-0 pr-4 text-base font-semibold bg-white text-brand-2-700">{optionListTitle}</h4>
            <div className="flex-1 border-t-2 border-gray-200"></div>
          </div> : null}

          <ul role="list" className="md:grid md:grid-cols-2 gap-x-6 gap-y-4">

            {optionItemText ? (<>
              {optionItemText.map(text =>
                <li key={text?._key} className="flex items-start">

                  {/* Icon */}
                  {icon?.name && (<span className="inline-flex items-center justify-center text-brand-2-500">
                    <Icon name={transformToUpperCase(icon?.name, ['-'])} size={'2em'} aria-hidden="true" />
                  </span>)}
                  <span className="ml-3 text-gray-500">{text?.title}</span>
                </li>
              )}
            </>) : null}

          </ul>
        </div>
      </div>
    )
  } else {
    return null
  }

}
