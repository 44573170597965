import React from 'react';
import PropTypes from 'prop-types'
// import Link from 'next/link';
// import Image from 'next/image';
import { Tagline } from '@/components/content/tagline'
// import { Linker } from '@/components/content/linker'
import { OptionItem } from '@/components/content/optionsTable/optionItem'
import { Grid } from "@/components/content/grid";
import { Text } from '@/components/layout/content-components/serializers/text'

OptionsTable.propTypes = {
  layout: PropTypes.string,
  tagline: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.array,
  icon: PropTypes.any,
  optionLayout: PropTypes.string,
  optionListTitle: PropTypes.string,
  optionItems: PropTypes.array,
}

export function OptionsTable(props: { layout: string; tagline: string; title: string; text: any; icon: any; optionLayout: string; optionListTitle: string; optionItems: any; }) {

  const { layout, title, tagline, text, icon, optionLayout, optionListTitle, optionItems } = props
  if (layout === 'centered') {
    return (
      <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
        <div className="relative bg-white">
          <div className="max-w-md mx-auto text-center sm:max-w-3xl md:px-6 lg:px-8 lg:max-w-7xl">

            {/* Tagline */}
            {tagline && <Tagline text={tagline} size='md' theme='light' classes='' />}

            {/* Title */}
            {title &&
              <h3 className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl mx-a">
                {title}
              </h3>}

            {/* Text */}
            {text && <div className="mx-auto mt-5 text-xl prose-p:text-gray-500 prose-a:underline hover:prose-a:no-underline max-w-prose"><Text blocks={text} /></div>}

          </div>

          {/* Options */}
          {(optionItems && optionItems?.length > 0) ?
            <div className="mt-12">
              {optionLayout === 'stacked' ? (
                <div>
                  {optionItems?.map((option: { _key: string; optionLayout: string; pricePerMonth: number; pricePerYear: number; text: any; title: string; callToAction: any; optionItemText: any; }) => (

                    <OptionItem
                      key={option._key}
                      layout={optionLayout ? optionLayout : null}
                      optionListTitle={optionListTitle ? optionListTitle : null}
                      title={option?.title}
                      icon={icon ? icon : null}
                      pricePerMonth={option?.pricePerMonth}
                      pricePerYear={option?.pricePerYear}
                      text={option?.text}
                      callToAction={option?.callToAction}
                      optionItemText={option?.optionItemText}
                    />
                  ))}
                </div>
              ) : (
                <Grid gridSize={optionItems?.length}>
                  {optionItems?.map((option: { _key: string; optionLayout: string; pricePerMonth: number; pricePerYear: number; text: any; title: string; callToAction: any; optionItemText: any; }) => (

                    <OptionItem
                      key={option._key}
                      layout={optionLayout}
                      optionListTitle={optionListTitle}
                      title={option?.title}
                      icon={icon}
                      pricePerMonth={option?.pricePerMonth}
                      pricePerYear={option?.pricePerYear}
                      text={option?.text}
                      callToAction={option?.callToAction}
                      optionItemText={option?.optionItemText}
                    />
                  ))}
                </Grid>
              )}
            </div>
            : null}

        </div>
      </section>
    )
  } else if (layout === 'side-by-side') { // Side-by-side
    return (
      <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
        {/* Tagline */}
        {tagline && <Tagline text={tagline} size='md' theme='light' classes='' />}

        <div className="relative bg-white md:grid md:grid-cols-12 md:gap-12">

          <div className="max-w-2xl md:col-span-4 md:text-left ">

            {/* Title */}
            {title &&
              <h3 className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                {title}
              </h3>}

            {/* Text */}
            {text && <div className="mt-5 text-xl prose-p:text-gray-500 prose-a:underline hover:prose-a:no-underline max-w-prose"><Text blocks={text} /></div>}

          </div>

          {/* Options */}
          {(optionItems && optionItems?.length > 0) ?
            <div className="mt-12 md:col-span-8">
              {optionLayout === 'stacked' ? (
                <div>
                  {optionItems?.map((option: { _key: string; optionLayout: string; pricePerMonth: number; pricePerYear: number; text: any; title: string; callToAction: any; optionItemText: any; }) => (

                    <OptionItem
                      key={option._key}
                      layout={optionLayout ? optionLayout : null}
                      optionListTitle={optionListTitle ? optionListTitle : null}
                      title={option?.title}
                      icon={icon ? icon : null}
                      pricePerMonth={option?.pricePerMonth}
                      pricePerYear={option?.pricePerYear}
                      text={option?.text}
                      callToAction={option?.callToAction}
                      optionItemText={option?.optionItemText}
                    />
                  ))}
                </div>
              ) : (
                <Grid gridSize={optionItems?.length}>
                  {optionItems?.map((option: { _key: string; optionLayout: string; pricePerMonth: number; pricePerYear: number; text: any; title: string; callToAction: any; optionItemText: any; }) => (

                    <OptionItem
                      key={option._key}
                      layout={optionLayout}
                      optionListTitle={optionListTitle}
                      title={option?.title}
                      icon={icon}
                      pricePerMonth={option?.pricePerMonth}
                      pricePerYear={option?.pricePerYear}
                      text={option?.text}
                      callToAction={option?.callToAction}
                      optionItemText={option?.optionItemText}
                    />
                  ))}
                </Grid>
              )}
            </div>
            : null}
        </div>
      </section>
    )
  } else { // Stacked (default) layout
    return (
      <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
        <div className="relative bg-white">
          <div className="max-w-md text-left sm:max-w-3xl lg:max-w-7xl">

            {/* Tagline */}
            {tagline && <Tagline text={tagline} size='md' theme='light' classes='' />}

            {/* Title */}
            {title &&
              <h3 className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                {title}
              </h3>}

            {/* Text */}
            {text && <div className="mt-5 text-xl prose-p:text-gray-500 prose-a:underline hover:prose-a:no-underline max-w-prose"><Text blocks={text} /></div>}

          </div>

          {/* Options */}
          {(optionItems && optionItems?.length > 0) ?
            <>
              {optionLayout === 'stacked' ? (
                <div className='pt-6 md:pt-8'>
                  {optionItems?.map((option: { _key: string; optionLayout: string; pricePerMonth: number; pricePerYear: number; text: any; title: string; callToAction: any; optionItemText: any; }) => (
                    <OptionItem
                      key={option._key}
                      layout={optionLayout ? optionLayout : null}
                      optionListTitle={optionListTitle ? optionListTitle : null}
                      title={option?.title}
                      icon={icon ? icon : null}
                      pricePerMonth={option?.pricePerMonth}
                      pricePerYear={option?.pricePerYear}
                      text={option?.text}
                      callToAction={option?.callToAction}
                      optionItemText={option?.optionItemText}
                    />
                  ))}
                </div>
              ) : (
                <div className="mt-12">
                  <Grid gridSize={optionItems?.length}>
                    {optionItems?.map((option: { _key: string; optionLayout: string; pricePerMonth: number; pricePerYear: number; text: any; title: string; callToAction: any; optionItemText: any; }) => (
                      <OptionItem
                        key={option._key}
                        layout={optionLayout ? optionLayout : null}
                        optionListTitle={optionListTitle ? optionListTitle : null}
                        title={option?.title}
                        icon={icon ? icon : null}
                        pricePerMonth={option?.pricePerMonth}
                        pricePerYear={option?.pricePerYear}
                        text={option?.text}
                        callToAction={option?.callToAction}
                        optionItemText={option?.optionItemText}
                      />
                    ))}
                  </Grid>
                </div>
              )}
            </>
            : null}
        </div>
      </section>
    )
  }
}
