import Script from 'next/script';

export default function EmbeddedTypeformView() {
  return (
    <>
      <div data-tf-live="01HZG0Q6T2579ZY8KFJJCD5Z8S"></div>
      <Script
        src="//embed.typeform.com/next/embed.js"
        strategy="lazyOnload"
      />
    </>
  )
}
