import PropTypes from 'prop-types'
import { Text } from '@/components/layout/content-components/serializers/text'
import { CallToAction } from '@/components/content/call-to-action'

CTASection.propTypes = {
  layout: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  text: PropTypes.array,
  callToActionPrimary: PropTypes.object,
  callToActionSecondary: PropTypes.object,
}

export function CTASection(props: { layout: string; title: string; subTitle: string; text: any; callToActionPrimary: any; callToActionSecondary: any; }) {

  const { layout, title, subTitle, text, callToActionPrimary, callToActionSecondary } = props

  if (layout === 'centered') {
    return (
      <div className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
        <div className="px-4 py-12 mx-auto text-center max-w-7xl sm:px-6 lg:py-16 lg:px-8">

          {title && <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">{title}</h2>}
          {subTitle && <h3 className="text-4xl font-bold tracking-tight text-brand-2-700 sm:text-6xl">{subTitle}</h3>}
          {/* Text */}
          {text && (<div className="mt-4 text-lg lg:mx-auto lg:text-xl prose-p:text-gray-500 prose-a:underline hover:prose-a:no-underline lg:max-w-prose"><Text blocks={text} /></div>
          )}

          {/* CTA Buttons/Links */}
          {(callToActionPrimary || callToActionSecondary) &&
            <div className="flex items-center justify-center mt-8 space-x-6 text-center">
              {/* CTA - Primary */}

              {callToActionPrimary && <CallToAction title={callToActionPrimary.title} url={callToActionPrimary?.url} type={callToActionPrimary?.type} icon={callToActionPrimary?.icon?.name} style={`primary`} size={`lg`} theme={(callToActionPrimary?.type === 'textIcon' || callToActionPrimary?.type === 'text') ? 'light' : 'dark'} fullWidth={false} />}

              {/* CTA - Secondary */}
              {callToActionSecondary && <CallToAction title={callToActionSecondary?.title} url={callToActionSecondary?.url} type={callToActionSecondary?.type} icon={callToActionSecondary?.icon?.name} style={`secondary`} size={`lg`} theme={`light`} fullWidth={false} />}
            </div>}

        </div>
      </div>
    )
  } else if (layout === 'centered-branded') {
    return (
      <div className="mb-8 rounded-md md:mb-12 lg:mb-16 inner-wrapper bg-slate-100">
        <div className="max-w-3xl px-4 pt-6 pb-8 mx-auto text-center lg:pt-10 lg:pb-12">

          {title && <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">{title}</h2>}
          {subTitle && <h3 className="text-4xl font-bold tracking-tight text-brand-2-700 sm:text-6xl">{subTitle}</h3>}
          {/* Text */}
          {text && (<div className="mt-4 text-lg lg:mx-auto lg:text-xl prose-p:text-gray-600 prose-a:underline hover:prose-a:no-underline lg:max-w-prose"><Text blocks={text} /></div>
          )}

          {/* CTA Buttons/Links */}
          {(callToActionPrimary || callToActionSecondary) &&
            <div className="flex items-center justify-center mt-8 space-x-6 text-center">
              {/* CTA - Primary */}

              {callToActionPrimary && <CallToAction title={callToActionPrimary.title} url={callToActionPrimary?.url} type={callToActionPrimary?.type} icon={callToActionPrimary?.icon} style={`primary`} size={`lg`} theme={(callToActionPrimary?.type === 'textIcon' || callToActionPrimary?.type === 'text') ? 'light' : 'dark'} fullWidth={false} />}

              {/* CTA - Secondary */}
              {callToActionSecondary && <CallToAction title={callToActionSecondary?.title} url={callToActionSecondary?.url} type={callToActionSecondary?.type} icon={callToActionSecondary?.icon} style={`secondary`} size={`lg`} theme={`light`} fullWidth={false} />}
            </div>}

        </div>
      </div>
    )
  } else if (layout === 'centered-branded-full') {
    return (
      <div className='bg-slate-100'>
        <div className="px-4 mb-8 md:mb-12 lg:mb-16 inner-wrapper">

          <div className="max-w-3xl pt-6 pb-8 mx-auto text-center lg:pt-10 lg:pb-12">

            {title && <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">{title}</h2>}
            {subTitle && <h3 className="text-4xl font-bold tracking-tight text-brand-2-700 sm:text-6xl">{subTitle}</h3>}
            {/* Text */}
            {text && (<div className="mt-4 text-lg lg:mx-auto lg:text-xl prose-p:text-gray-600 prose-a:underline hover:prose-a:no-underline lg:max-w-prose"><Text blocks={text} /></div>
            )}

            {/* CTA Buttons/Links */}
            {(callToActionPrimary || callToActionSecondary) &&
              <div className="flex items-center justify-center mt-8 space-x-6 text-center">
                {/* CTA - Primary */}

                {callToActionPrimary && <CallToAction title={callToActionPrimary.title} url={callToActionPrimary?.url} type={callToActionPrimary?.type} icon={callToActionPrimary?.icon} style={`primary`} size={`lg`} theme={(callToActionPrimary?.type === 'textIcon' || callToActionPrimary?.type === 'text') ? 'light' : 'dark'} fullWidth={false} />}

                {/* CTA - Secondary */}
                {callToActionSecondary && <CallToAction title={callToActionSecondary?.title} url={callToActionSecondary?.url} type={callToActionSecondary?.type} icon={callToActionSecondary?.icon} style={`secondary`} size={`lg`} theme={`light`} fullWidth={false} />}
              </div>}

          </div>
        </div>
      </div>
    )
  } else if (layout === 'justified') {
    return (
      <div className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
        <div className="pt-6 pb-8 mx-auto rounded-md lg:flex lg:items-center lg:justify-between lg:pt-10 lg:pb-12">

          <div className="flex flex-col">
            {title && <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">{title}</h2>}
            {subTitle && <h3 className="text-4xl font-bold tracking-tight text-brand-2-700 sm:text-6xl">{subTitle}</h3>}
            {/* Text */}
            {text && (<div className="mt-4 text-lg lg:mx-auto lg:text-xl prose-p:text-gray-500 prose-a:underline hover:prose-a:no-underline lg:max-w-prose"><Text blocks={text} /></div>
            )}
          </div>

          {/* CTA Buttons/Links */}
          {(callToActionPrimary || callToActionSecondary) &&
            <div className="flex mt-8 space-x-4 lg:mt-0 lg:flex-shrink-0">
              {/* CTA - Primary */}

              {callToActionPrimary && <CallToAction title={callToActionPrimary.title} url={callToActionPrimary?.url} type={callToActionPrimary?.type} icon={callToActionPrimary?.icon} style={`primary`} size={`lg`} theme={(callToActionPrimary?.type === 'textIcon' || callToActionPrimary?.type === 'text') ? 'light' : 'dark'} fullWidth={false} />}

              {/* CTA - Secondary */}
              {callToActionSecondary && <CallToAction title={callToActionSecondary?.title} url={callToActionSecondary?.url} type={callToActionSecondary?.type} icon={callToActionSecondary?.icon} style={`secondary`} size={`lg`} theme={`light`} fullWidth={false} />}
            </div>}

        </div>
      </div>
    )
  } else if (layout === 'justified-branded') {
    return (
      <div className="mb-8 rounded-md md:mb-12 lg:mb-16 inner-wrapper bg-slate-100">
        <div className="px-4 pt-6 pb-8 mx-auto lg:px-10 lg:pt-10 lg:pb-12 lg:flex lg:items-center lg:justify-between">

          <div className="flex flex-col">
            {title && <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">{title}</h2>}
            {subTitle && <h3 className="text-4xl font-bold tracking-tight text-brand-2-700 sm:text-6xl">{subTitle}</h3>}
            {/* Text */}
            {text && (<div className="mt-4 text-lg lg:mx-auto lg:text-xl prose-p:text-gray-600 prose-a:underline hover:prose-a:no-underline lg:max-w-prose"><Text blocks={text} /></div>
            )}
          </div>

          {/* CTA Buttons/Links */}
          {(callToActionPrimary || callToActionSecondary) &&
            <div className="flex mt-8 space-x-4 lg:mt-0 lg:flex-shrink-0">
              {/* CTA - Primary */}

              {callToActionPrimary && <CallToAction title={callToActionPrimary.title} url={callToActionPrimary?.url} type={callToActionPrimary?.type} icon={callToActionPrimary?.icon} style={`primary`} size={`lg`} theme={(callToActionPrimary?.type === 'textIcon' || callToActionPrimary?.type === 'text') ? 'light' : 'dark'} fullWidth={false} />}

              {/* CTA - Secondary */}
              {callToActionSecondary && <CallToAction title={callToActionSecondary?.title} url={callToActionSecondary?.url} type={callToActionSecondary?.type} icon={callToActionSecondary?.icon} style={`secondary`} size={`lg`} theme={`light`} fullWidth={false} />}
            </div>}

        </div>
      </div>
    )
  } else if (layout === 'justified-branded-full') {
    return (<div className="bg-slate-100">
      <div className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
        <div className="px-4 pt-6 pb-8 mx-auto lg:px-10 lg:pt-10 lg:pb-12 lg:flex lg:items-center lg:justify-between">
          <div className="flex flex-col">
            {title && <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">{title}</h2>}
            {subTitle && <h3 className="text-4xl font-bold tracking-tight text-brand-2-700 sm:text-6xl">{subTitle}</h3>}
            {/* Text */}
            {text && (<div className="mt-4 text-lg lg:mx-auto lg:text-xl prose-p:text-gray-600 prose-a:underline hover:prose-a:no-underline lg:max-w-prose"><Text blocks={text} /></div>
            )}
          </div>
          {/* CTA Buttons/Links */}
          {(callToActionPrimary || callToActionSecondary) &&
            <div className="flex mt-8 space-x-4 lg:mt-0 lg:flex-shrink-0">
              {/* CTA - Primary */}

              {callToActionPrimary && <CallToAction title={callToActionPrimary.title} url={callToActionPrimary?.url} type={callToActionPrimary?.type} icon={callToActionPrimary?.icon} style={`primary`} size={`lg`} theme={(callToActionPrimary?.type === 'textIcon' || callToActionPrimary?.type === 'text') ? 'light' : 'dark'} fullWidth={false} />}

              {/* CTA - Secondary */}
              {callToActionSecondary && <CallToAction title={callToActionSecondary?.title} url={callToActionSecondary?.url} type={callToActionSecondary?.type} icon={callToActionSecondary?.icon} style={`secondary`} size={`lg`} theme={`light`} fullWidth={false} />}
            </div>}
        </div>
      </div>
    </div>)
  } else if (layout === 'stacked') {
    return (
      <div className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
        <div className="pt-6 pb-8 mx-auto rounded-md lg:flex lg:flex-col lg:pt-10 lg:pb-12">

          <div className="flex flex-col">
            {title && <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">{title}</h2>}
            {subTitle && <h3 className="text-4xl font-bold tracking-tight text-brand-2-700 sm:text-6xl">{subTitle}</h3>}
            {/* Text */}
            {text && (<div className="mt-4 text-lg lg:text-xl prose-p:text-gray-500 prose-a:underline hover:prose-a:no-underline lg:max-w-prose"><Text blocks={text} /></div>
            )}
          </div>

          {/* CTA Buttons/Links */}
          {(callToActionPrimary || callToActionSecondary) &&
            <div className="flex mt-8 space-x-4">
              {/* CTA - Primary */}

              {callToActionPrimary && <CallToAction title={callToActionPrimary.title} url={callToActionPrimary?.url} type={callToActionPrimary?.type} icon={callToActionPrimary?.icon} style={`primary`} size={`lg`} theme={(callToActionPrimary?.type === 'textIcon' || callToActionPrimary?.type === 'text') ? 'light' : 'dark'} fullWidth={false} />}

              {/* CTA - Secondary */}
              {callToActionSecondary && <CallToAction title={callToActionSecondary?.title} url={callToActionSecondary?.url} type={callToActionSecondary?.type} icon={callToActionSecondary?.icon} style={`secondary`} size={`lg`} theme={`light`} fullWidth={false} />}
            </div>}

        </div>
      </div>
    )
  } else if (layout === 'stacked-banded') {
    return (
      <div className="mb-8 rounded-md md:mb-12 lg:mb-16 inner-wrapper bg-slate-100">
        <div className="px-4 pt-6 pb-8 mx-auto lg:px-10 lg:pt-10 lg:pb-12 lg:flex lg:flex-col">

          <div className="flex flex-col">
            {title && <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">{title}</h2>}
            {subTitle && <h3 className="text-4xl font-bold tracking-tight text-brand-2-700 sm:text-6xl">{subTitle}</h3>}
            {/* Text */}
            {text && (<div className="mt-4 text-lg lg:text-xl prose-p:text-gray-600 prose-a:underline hover:prose-a:no-underline lg:max-w-prose"><Text blocks={text} /></div>
            )}
          </div>

          {/* CTA Buttons/Links */}
          {(callToActionPrimary || callToActionSecondary) &&
            <div className="flex mt-8 space-x-4">
              {/* CTA - Primary */}

              {callToActionPrimary && <CallToAction title={callToActionPrimary.title} url={callToActionPrimary?.url} type={callToActionPrimary?.type} icon={callToActionPrimary?.icon} style={`primary`} size={`lg`} theme={(callToActionPrimary?.type === 'textIcon' || callToActionPrimary?.type === 'text') ? 'light' : 'dark'} fullWidth={false} />}

              {/* CTA - Secondary */}
              {callToActionSecondary && <CallToAction title={callToActionSecondary?.title} url={callToActionSecondary?.url} type={callToActionSecondary?.type} icon={callToActionSecondary?.icon} style={`secondary`} size={`lg`} theme={`light`} fullWidth={false} />}
            </div>}

        </div>
      </div>
    )
  } else if (layout === 'stacked-banded-full') {
    return (
      <div className="bg-slate-100">
      <div className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
        <div className="flex flex-col px-4 pt-6 pb-8 mx-auto lg:px-10 lg:pt-10 lg:pb-12">

          <div className="flex flex-col">
            {title && <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">{title}</h2>}
            {subTitle && <h3 className="text-4xl font-bold tracking-tight text-brand-2-700 sm:text-6xl">{subTitle}</h3>}
            {/* Text */}
            {text && (<div className="mt-4 text-lg lg:text-xl prose-p:text-gray-600 prose-a:underline hover:prose-a:no-underline lg:max-w-prose"><Text blocks={text} /></div>
            )}
          </div>

          {/* CTA Buttons/Links */}
          {(callToActionPrimary || callToActionSecondary) &&
            <div className="flex mt-8 space-x-4">
              {/* CTA - Primary */}

              {callToActionPrimary && <CallToAction title={callToActionPrimary.title} url={callToActionPrimary?.url} type={callToActionPrimary?.type} icon={callToActionPrimary?.icon} style={`primary`} size={`lg`} theme={(callToActionPrimary?.type === 'textIcon' || callToActionPrimary?.type === 'text') ? 'light' : 'dark'} fullWidth={false} />}

              {/* CTA - Secondary */}
              {callToActionSecondary && <CallToAction title={callToActionSecondary?.title} url={callToActionSecondary?.url} type={callToActionSecondary?.type} icon={callToActionSecondary?.icon} style={`secondary`} size={`lg`} theme={`light`} fullWidth={false} />}
            </div>}

        </div>
      </div>
      </div>
    )
  } else {
    return null
  }
}
