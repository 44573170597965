import PropTypes from 'prop-types'
import { Contact } from '@/components/content/view/contact'
import { JobApp } from '@/components/content/view/job-app'
import { ContentView } from '@/components/content/view/content-view/'
import { TeamView } from '@/components/content/view/team/'
import { Subscribe } from '@/components/services/mailchimp/subscribe'
import { UpcomingEvents } from '@/components/content/view/events/'
import { LatestPosts } from '@/components/content/view/posts/'

// FOSSIL: START
import { AllProductsView } from '@/components/content/view/all-products'
import { FeaturedProductsView } from '@/components/content/view/featured-products'
import EmbeddedTypeformView from './content-view/embedded-typeform-view'
// FOSSIL: END

ViewComponent.propTypes = {
  contentView: PropTypes.string.isRequired,
  webform: PropTypes.object,
  view: PropTypes.object,
  filter: PropTypes.string
}

export function ViewComponent(props: { contentView: string, webform: any; view: any; filter: any; }) {
  const { contentView, webform, view, filter } = props
  if (contentView === 'componentViewContact') {
    return (
      <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
        <Contact webform={webform} />
      </section>
    )
  } else if (contentView === 'componentViewJobApp') {
      return (
        <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
          <JobApp webform={webform} />
        </section>
      )
  } else if (contentView === 'componentViewPosts' || contentView === 'componentViewEvents') {
    return (
      <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
        <ContentView view={view} />
      </section>
    )
  } else if (contentView === 'componentViewTeam') {
    return (
      <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
        <TeamView view={view} />
      </section>
    )
  } else if (contentView === 'componentViewLatestPosts') {
    return (
      <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
        <LatestPosts />
      </section>
    )
  } else if (contentView === 'componentViewUpcomingEvents') {
    return (
      <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
        <UpcomingEvents />
      </section>
    )
  } else if (contentView === 'componentMCSubForm') {
    return (
      <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
        {/* <Subscribe /> */}
      </section>
    )
  // FOSSIL VIEWS: START
  } else if (contentView === 'componentViewPosts' || contentView === 'componentViewEvents') {
    return (
      <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
        <ContentView view={view} />
      </section>
    )
  } else if (contentView === 'componentViewAllProducts') {
    return (
      <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
        <AllProductsView view={view} filter={filter} />
      </section>
    )
  } else if (contentView === 'componentViewFeaturedProducts') {
    return (
      <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
        <FeaturedProductsView view={view} />
      </section>
    )
  } else if (contentView === 'componentEmbeddedTypeform') {
    return (
      <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
        <EmbeddedTypeformView />
      </section>
    )
  // FOSSIL VIEWS: END
  } else {
    return null
  }
}
