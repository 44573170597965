import React from 'react';
import PropTypes from 'prop-types'
import { Tagline } from '@/components/content/tagline'
import { FAQItems } from '@/components/content/faq/faqItems'
import { Text } from '@/components/layout/content-components/serializers/text'
// import { OptionItem } from '../optionsTable/optionItem';

FAQSection.propTypes = {
  layout: PropTypes.string,
  tagline: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.array,
  faqsLayout: PropTypes.string,
  faqs: PropTypes.array,
}

export function FAQSection(props: { layout: string; tagline: string; title: string; text: any; faqsLayout: string; faqs: any; }) {

  const { layout, title, tagline, text, faqsLayout, faqs } = props
  if (layout === 'centered') {
    return (
      <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
        <div className="relative bg-white">
          <div className="max-w-md mx-auto text-center sm:max-w-3xl md:px-6 lg:px-8 lg:max-w-7xl">

            {/* Tagline */}
            {tagline && <Tagline text={tagline} size='md' theme='light' classes='' />}

            {/* Title */}
            {title &&
              <h3 className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl mx-a">
                {title}
              </h3>}

            {/* Text */}
            {text && <div className="mx-auto mt-5 text-xl prose-p:text-gray-500 prose-a:underline hover:prose-a:no-underline max-w-prose"><Text blocks={text} /></div>}

          </div>

          {/* FAQs */}
          {(faqs && faqs?.length > 0) && <FAQItems faqs={faqs} layout={faqsLayout} />}


        </div>
      </section>
    )
  } else if (layout === 'side-by-side') { // Side-by-side
    return (
      <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
        {/* Tagline */}
        {tagline && <Tagline text={tagline} size='md' theme='light' classes='' />}

        <div className="relative bg-white md:grid md:grid-cols-12 md:gap-12">

          <div className="max-w-2xl md:col-span-4 md:text-left ">

            {/* Title */}
            {title &&
              <h3 className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                {title}
              </h3>}

            {/* Text */}
            {text && <div className="mt-5 text-xl prose-p:text-gray-500 prose-a:underline hover:prose-a:no-underline max-w-prose"><Text blocks={text} /></div>}

          </div>

          {/* FAQs */}
          {(faqs && faqs?.length > 0) && <div className="mt-12 md:mt-0 md:col-span-8"><FAQItems faqs={faqs} layout={faqsLayout} /></div>}

        </div>
      </section>
    )
  } else { // Stacked (default) layout
    return (
      <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
        <div className="relative bg-white">
          <div className="max-w-md text-left sm:max-w-3xl lg:max-w-7xl">

            {/* Tagline */}
            {tagline && <Tagline text={tagline} size='md' theme='light' classes='' />}

            {/* Title */}
            {title &&
              <h3 className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                {title}
              </h3>}

            {/* Text */}
            {text && <div className="mt-5 text-xl prose-p:text-gray-500 prose-a:underline hover:prose-a:no-underline max-w-prose"><Text blocks={text} /></div>}

          </div>

          {/* FAQs */}
          {(faqs && faqs?.length > 0) && <FAQItems faqs={faqs} layout={faqsLayout} />}

        </div>
      </section>
    )

  }
}
