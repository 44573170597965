import { useRef, useState, useEffect, useCallback } from 'react'
import { useIntersectionObserver } from 'usehooks-ts'
import { useCookies } from 'react-cookie'
import { ProductTeaser } from "@/components/content/product/teaser";
import { Loader } from '@/components/shared/loader'
import { capitalizeFirstLetter } from '@/utils/helpers'
import ReactPaginate from 'react-paginate';
import { ProductCollections } from './product-collections'

export function AllProductsView({ view, filter }) {

  const [showFilterPannel, setShowFilterPannel] = useState(false)
  const ref = useRef(null)
  const entry = useIntersectionObserver(ref, {
    root: null,
    rootMargin: "0px 0px -100%",
    threshold: 0
  })
  const isVisible = !!entry?.isIntersecting

  //  SET STATE
  const [isLoaded, setIsLoaded] = useState(false)

  // const [productsPerPage, setProductsPerPage] = useState(48);
  const [products, setProducts] = useState(view?.products)
  const [filterText, setFilterText] = useState(null)
  const [productAvailability, setProductAvailability] = useState(true)
  const [productCategory, setProductCategory] = useState('all')
  const [selectedGreengoodsCategory, setSelectedGreengoodsCategory] = useState([])
  const [selectedSizeClass, setSelectedSizeClass] = useState([])
  const [selectedUSDAHardness, setSelectedUSDAHardness] = useState([])
  const [selectedProductCollections, setSelectedProductCollections] = useState([])

  // We start with an empty list of items.
  const [endOffset, setEndOffset] = useState(0)
  // const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(48);

  // COOKIES
  const [cookies, setCookie, removeCookie] = useCookies([
    'itemOffset',
    'endOffset',
    'itemsPerPage',
    'filterText',
    'productAvailability',
    'productCategory',
    'selectedGreengoodsCategory',
    'selectedSizeClass',
    'selectedUSDAHardness',
    'selectedProductCollections',
  ])
  const maxAge = 3600 // 30 (2592000) days
  const cookieOptions = { path: '/', maxAge: maxAge, sameSite: true }

  //  SET CONT
  const greengoodsCategoryList = [...new Set(view?.products.map(product => product?.greengoodsCategory))].filter(Boolean).sort();
  const productCollectionsList = view?.productCollections?.sort((a, b) => a.sortOrder - b.sortOrder) || [];
  const usdaHardinessZoneList = [...new Map(view?.products.map(product =>
    [product?.productVariations[0]?.greengoods?.usdaHardinessZone, product])).values()].map(product => {
      return product?.productVariations?.length >= 0 && parseInt(product?.productVariations[0]?.greengoods?.usdaHardinessZone?.trim())
    }).filter(product => product !== null).sort((a, b) => a - b).filter(Boolean).reduce(
      (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
      [],
    );

  const sizeClassList = [...new Map(view?.products.map(product =>
    [product?.productVariations[0]?.greengoods?.sizeClass, product])).values()].map(product => {
      return product?.productVariations?.length >= 0 && product?.productVariations[0]?.greengoods?.sizeClass?.trim()
    }).filter(product => product !== null).sort((a, b) => a - b).filter(Boolean).reduce(
      (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
      [],
    );

  // FILTER PRODUCTS
  const filterCollections = (item, selectedIds) => {
    return item.productCollections && item.productCollections.some(collection => {
      return selectedIds.includes(collection._id);
    });
  };

  const filteredProducts = view?.products.filter(product => {
    let scientificName = product?.productVariations[0]?.greengoods?.scientificName || '';

    return (
      (productAvailability === true ? product.available === true : product) &&
      (productCategory !== 'all' ? product.productCategory === productCategory : product) &&
      (
        filterText ?
          (product?.title?.toLowerCase()?.includes(filterText.toLowerCase())) || (scientificName.toLowerCase()?.includes(filterText.toLowerCase()))
          : product
      ) &&
      (selectedGreengoodsCategory?.length > 0 ? selectedGreengoodsCategory?.includes(product?.greengoodsCategory) : product) &&
      (selectedUSDAHardness?.length > 0 ? selectedUSDAHardness?.includes(product?.productVariations[0]?.greengoods?.usdaHardinessZone) : product) &&
      (selectedSizeClass?.length > 0 ? selectedSizeClass?.includes(product?.productVariations[0]?.greengoods?.sizeClass) : product) &&
      (productAvailability === true ? product.available === true : product) &&
      (selectedProductCollections?.length > 0 ? filterCollections(product, selectedProductCollections) : product)
    )
  })

  // NEW PAGINATION
  useEffect(() => {
    setEndOffset(itemOffset + itemsPerPage)
    setPageCount(Math.ceil(filteredProducts.length / itemsPerPage));
  }, [endOffset, filteredProducts.length, itemOffset, itemsPerPage]);

  // NEW PAGINATION CLICK
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % filteredProducts.length;
    console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
    setItemOffset(newOffset);
    setCookie('itemOffset', newOffset, cookieOptions);
    setCookie('endOffset', newOffset + itemsPerPage, cookieOptions);
    setProducts(filteredProducts);
    //  Scroll to view results
    document.querySelector('#search').scrollIntoView({
      behavior: 'smooth'
    });
  };

  // Change Items Per Page
  const handleItemsPerPage = (val) => {
    setCookie('itemsPerPage', val, cookieOptions);
    setItemsPerPage(val);
    setItemOffset(0)
    setCookie('itemOffset', 0, cookieOptions);
    setCookie('endOffset', endOffset, cookieOptions);
    //  Scroll to view results
    document.querySelector('#search').scrollIntoView({
      behavior: 'smooth'
    });
    // Filter the current products
    setProducts(filteredProducts)
  }

  const handleProductsFilter = (e) => {
    e.preventDefault();
    // Reset Pagination to page one
    setItemOffset(0)
    setCookie('itemOffset', 0, cookieOptions);
    setCookie('endOffset', endOffset, cookieOptions);
    //  Scroll to view results
    document.querySelector('#search').scrollIntoView({
      behavior: 'smooth'
    });
    // Filter the current products
    setProducts(filteredProducts)
  }

  const handleAvailability = () => {
    setCookie('productAvailability', !productAvailability, cookieOptions);
    setProductAvailability(!productAvailability)
  }

  const handleProductCategory = (val) => {
    setCookie('productCategory', val, cookieOptions);
    setProductCategory(val)
  }

  const handleFilterText = (val) => {
    setFilterText(val);
    setCookie('filterText', val, cookieOptions);
    // handleAutoProductsFilter();
  }

  const handleSetSize = (e) => {
    let newVal = e.target.value
    let isChecked = e.target.checked
    let doesInclude = selectedSizeClass.includes(newVal)
    let newItems = selectedSizeClass.slice();
    if (isChecked && !doesInclude) {
      newItems = [...selectedSizeClass, newVal]
    } else if (!isChecked && doesInclude) {
      newItems = selectedSizeClass.filter(e => e !== newVal)
    }
    setSelectedSizeClass(newItems)
    setCookie('selectedSizeClass', newItems, cookieOptions);
  }

  const handleSetGreengoodsCategory = (e) => {
    let newVal = e.target.value
    let isChecked = e.target.checked
    let doesInclude = selectedGreengoodsCategory.includes(newVal)
    let newItems = selectedGreengoodsCategory.slice();
    if (isChecked && !doesInclude) {
      newItems = [...selectedGreengoodsCategory, newVal]
    } else if (!isChecked && doesInclude) {
      newItems = selectedGreengoodsCategory.filter(e => e !== newVal)
    }
    setSelectedGreengoodsCategory(newItems)
    setCookie('selectedGreengoodsCategory', newItems, cookieOptions);
  }

  const handleSetProductCollections = (e) => {
    let newVal = e.target.value
    let isChecked = e.target.checked
    let doesInclude = selectedProductCollections.includes(newVal)
    let newItems = selectedProductCollections.slice();
    if (isChecked && !doesInclude) {
      newItems = [...selectedProductCollections, newVal]
    } else if (!isChecked && doesInclude) {
      newItems = selectedProductCollections.filter(e => e !== newVal)
    }
    setSelectedProductCollections(newItems)
    setCookie('selectedProductCollections', newItems, cookieOptions);
  }

  const handleSetProductCollection = (e) => {
    setSelectedProductCollections([e])
    setCookie('selectedProductCollections', [e], cookieOptions);
  }

  const handleSetUSDAHardness = (e) => {
    let newVal = e.target.value
    let isChecked = e.target.checked
    let doesInclude = selectedUSDAHardness.includes(newVal)
    let newItems = selectedUSDAHardness.slice();
    if (isChecked && !doesInclude) {
      newItems = [...selectedUSDAHardness, newVal]
    } else if (!isChecked && doesInclude) {
      newItems = selectedUSDAHardness.filter(e => e !== newVal)
    }
    setSelectedUSDAHardness(newItems);
    setCookie('selectedUSDAHardness', newItems, cookieOptions);
  }

  const handleProductsReset = (e) => {
    e.preventDefault();
    // Reset Pagination to page one
    setItemOffset(0)
    // Reset to the initial set of products
    setProducts(view.products)
    setSelectedGreengoodsCategory([]);
    setSelectedUSDAHardness([]);
    setSelectedSizeClass([]);
    setFilterText(null);
    setProductCategory('all');
    setSelectedProductCollections([]);

    //  Reset Cookies
    removeCookie('endOffset', cookieOptions)
    removeCookie('itemOffset', cookieOptions);
    removeCookie('endOffset', cookieOptions);
    removeCookie('itemsPerPage', cookieOptions);
    removeCookie('filterText', cookieOptions)
    removeCookie('productCategory', cookieOptions)
    removeCookie('selectedGreengoodsCategory', cookieOptions)
    removeCookie('selectedSizeClass', cookieOptions)
    removeCookie('selectedUSDAHardness', cookieOptions)
    removeCookie('selectedProductCollections', cookieOptions)
    document.querySelector('#search').scrollIntoView({
      behavior: 'smooth'
    });
  }

  useEffect(() => {
    if (cookies['itemOffset'] || cookies['endOffset'] || cookies['itemsPerPage'] || cookies['filterText'] || cookies['productAvailability'] || cookies['productCategory'] || cookies['selectedGreengoodsCategory'] || cookies['selectedSizeClass'] || cookies['selectedUSDAHardness'] || cookies['selectedProductCollections']) {
      // Pagination
      cookies['itemOffset'] && setItemOffset(parseInt(cookies['itemOffset']));
      cookies['endOffset'] && setEndOffset(parseInt(cookies['endOffset']));
      cookies['itemsPerPage'] && setItemsPerPage(parseInt(cookies['itemsPerPage']));
      // Search / Filtering
      cookies['filterText'] && setFilterText(cookies['filterText']);
      cookies['productAvailability'] && setProductAvailability(JSON.parse(cookies['productAvailability']));
      cookies['productCategory'] && setProductCategory(cookies['productCategory']);
      cookies['selectedGreengoodsCategory'] && setSelectedGreengoodsCategory(cookies['selectedGreengoodsCategory']);
      cookies['selectedSizeClass'] && setSelectedSizeClass(cookies['selectedSizeClass']);
      cookies['selectedUSDAHardness'] && setSelectedUSDAHardness(cookies['selectedUSDAHardness']);
      cookies['selectedProductCollections'] && setSelectedProductCollections(cookies['selectedProductCollections']);
      setProducts(filteredProducts)
    } else {
      setProducts(filteredProducts)
    }
    setIsLoaded(true);
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endOffset, filterText, itemsPerPage, productAvailability, productCategory, selectedGreengoodsCategory, selectedSizeClass, selectedUSDAHardness, selectedProductCollections, endOffset]);

  if ((!view?.products && products?.length < 1) || isLoaded === false) {
    return <Loader />
  }



  return (
    <div>
      {/* Product Collections  */}
      {(productCollectionsList && productCollectionsList?.length > 1) && <ProductCollections data={productCollectionsList} onSetProductCollection={handleSetProductCollection} />}

      {/* Product Filter and View */}
      <div className="grid row-span-2 pb-8 mt-8 lg:pb-12 lg:mt-12 gap-y-8 lg:grid-cols-12 lg:items-start lg:gap-x-10 lg:gap-y-10">

        {/* <!-- Filters --> */}
        <aside className={` z-10 col-span-12 md:top-0 md:sticky transition-all ${isVisible ? 'md:shadow-2xl md:transition-all' : ''}`} ref={ref}>
          <form onSubmit={handleProductsFilter} onReset={handleProductsReset}>

            <section aria-labelledby="filter-heading" className="relative z-10 mb-4 bg-white border-t border-b border-gray-200 md:mb-0">
              <h2 id="filter-heading" className="sr-only">Filters</h2>

              <div className="relative flex flex-col items-start justify-between py-4 text-sm bg-white lg:items-center sm:flex-row">

                <div className="flex mb-4 space-x-6 divide-x divide-gray-200 ppl-0 md:pl-4 md:mb-0">
                  {filter !== 'hardgoods' && <div>
                    <button type="button" className="flex items-center text-xs font-medium text-gray-700 group" aria-controls="disclosure-1" aria-expanded="false" onClick={() => setShowFilterPannel(!showFilterPannel)}>
                      <svg className="flex-none w-5 h-5 mr-2 text-gray-400 group-hover:text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z" clipRule="evenodd" />
                      </svg>
                      {showFilterPannel ? 'Close' : 'Show'} Filters
                    </button>
                  </div>}
                </div>

                {/* Search Button */}
                <div className='flex flex-col justify-end flex-none w-full px-0 pr-0 space-y-6 divide-gray-200 lg:flex-row lg:items-center lg:space-x-6 lg:divide-x lg:space-y-0 lg:divide-y-0 sm:w-3/4 lg:w-auto md:pr-4'>

                  {/* Product Availability */}
                  <div className="inline-flex items-center space-x-2 ">
                    <label className="flex-none block text-xs font-semibold text-gray-700">Show In-Stock Only</label>
                    <div className="flex items-center col-span-6">
                      <input id="" name="category[]" value={productAvailability} type="checkbox" className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500" defaultChecked={productAvailability && true} onChange={() => (handleAvailability())} checked={productAvailability && true} />
                    </div>
                  </div>

                  {/* Product Category */}
                  {filter === 'all' &&
                    <div className="inline-flex items-center pl-4 space-x-2">
                      <label className="flex-none block text-xs font-semibold text-gray-700">Category</label>
                      <select
                        className="block w-full px-2.5 py-1.5 text-xs border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 min-w-[110px]"
                        defaultValue={productCategory} onChange={e => handleProductCategory(e.target.value)}>
                        <option value="all">All</option>
                        <option value="greengoods">Greengoods</option>
                        <option value="hardgoods">Hardgoods</option>
                      </select>

                    </div>}



                  {/* Filter by Title or Scientific name */}
                  <div className="inline-flex items-center pl-6 space-x-2 lg:min-w-[300px]">
                    <label className="block text-xs font-semibold text-gray-700">Filter</label>
                    <div className="w-full">
                      <input
                        className="block w-full text-xs border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                        type="search"
                        placeholder="Search"
                        onChange={e => handleFilterText(e.target.value)} // setFilterText(e.target.value)
                        defaultValue={filterText}
                        value={filterText}
                      />
                    </div>
                  </div>

                  <div className='flex items-center justify-end pl-6'>
                    <div>
                      <button type="submit" className="flex items-center justify-center px-2.5 py-1.5 text-xs font-medium text-white bg-brand-1-500 border border-transparent rounded-md shadow-sm hover:bg-brand-1-700 transition-all focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-1-200" >Search</button>
                    </div>
                    <div className="pl-6">
                      <button className="text-gray-500" type="reset">Clear</button>
                    </div>
                  </div>

                </div>
              </div>

              <div className={` transition-all bg-white border-t border-gray-200 ${showFilterPannel ? 'opacity-100 visible h-auto  transition-all mt-0' : 'opacity-0 invisible h-0 p-0 '}`} id="disclosure-1">
                <div className="grid-cols-12 gap-10 px-8 py-6 text-sm bg-white sm:grid">

                {/* selected Product Collections */}

                  <div className="col-span-12 md:col-span-3">

                    <fieldset>
                      <legend className="block font-medium">Product Collections</legend>
                      <div className="mt-3 lg:grid lg:grid-cols-12 lg:gap-x-2 lg:gap-y-2">
                        {productCollectionsList && productCollectionsList.map((greenCat, i) =>
                          <div className="flex items-center col-span-12" key={i}>
                            <input id="" name="collection[]" value={greenCat?._id} type="checkbox" className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500" defaultChecked={selectedProductCollections?.includes(greenCat?._id) && true} onChange={e => handleSetProductCollections(e)} checked={selectedProductCollections?.includes(greenCat?._id) && true} />
                            <label htmlFor="" className="ml-3 text-sm text-gray-500">{capitalizeFirstLetter(greenCat?.title)}</label>
                          </div>)}
                      </div>
                    </fieldset>
                  </div>


                  <div className="col-span-12 md:col-span-4">
                    {/* Greengoods */}
                    <fieldset>
                      <legend className="block font-medium">Greengoods Categories</legend>
                      <div className="mt-3 lg:grid lg:grid-cols-12 lg:gap-x-2 lg:gap-y-2">
                        {greengoodsCategoryList && greengoodsCategoryList.map((greenCat, i) =>
                          <div className="flex items-center col-span-6" key={i}>
                            <input id="" name="category[]" value={greenCat} type="checkbox" className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500" defaultChecked={selectedGreengoodsCategory?.includes(greenCat) && true} onChange={e => handleSetGreengoodsCategory(e)} checked={selectedGreengoodsCategory?.includes(greenCat) && true} />
                            <label htmlFor="" className="ml-3 text-sm text-gray-500">{capitalizeFirstLetter(greenCat)}</label>
                          </div>)}
                      </div>
                    </fieldset>
                  </div>

                  <div className="col-span-12 md:col-span-3">
                    {/* USDA Hardiness Zone */}
                    <fieldset>
                      <legend className="block font-medium">USDA Hardiness Zone</legend>
                      <div className="mt-3 lg:grid lg:grid-cols-12 lg:gap-x-2 lg:gap-y-2">
                        {usdaHardinessZoneList && usdaHardinessZoneList.map((usdaCat, i) =>
                          <div className="flex items-center col-span-6" key={i}>
                            <input id="" name="usdaHardinessZone[]" value={usdaCat.toString()} type="checkbox" className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                              defaultChecked={selectedUSDAHardness?.includes(usdaCat.toString()) && true}
                              onChange={e => handleSetUSDAHardness(e)}
                              checked={selectedUSDAHardness?.includes(usdaCat.toString()) && true}
                            />
                            <label htmlFor="" className="ml-3 text-sm text-gray-500">{usdaCat}</label>
                          </div>)}
                      </div>
                    </fieldset>
                  </div>

                  <div className="col-span-12 md:col-span-2">
                    {/* Sizes */}
                    <fieldset>
                      <legend className="block font-medium">Size</legend>
                      <div className="mt-3 lg:grid lg:grid-cols-12 lg:gap-x-2 lg:gap-y-2">
                        {sizeClassList && sizeClassList.map((size, i) =>
                          <div className="flex items-center col-span-12" key={i}>
                            <input value={size} type="checkbox" className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                              defaultChecked={selectedSizeClass?.includes(size) && true}
                              onChange={e => handleSetSize(e)} // setSize(e)
                              checked={selectedSizeClass?.includes(size) && true}
                            />
                            <label htmlFor="" className="ml-3 text-sm text-gray-500">{capitalizeFirstLetter(size)}</label>
                          </div>)}
                      </div>
                    </fieldset>
                  </div>

                </div>

                <div className='flex justify-end mr-10 -mt-16 space-x-4'>
                  <button type="submit" className="flex items-center justify-center px-2.5 py-1.5 text-xs font-medium text-white bg-brand-1-500 border border-transparent rounded-md shadow-sm hover:bg-brand-1-700 transition-all focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-1-20" >Filter</button>
                  <button className="inline-flex justify-center px-2.5 py-1.5 text-xs font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500" type="reset">Reset</button>
                </div>
              </div>

            </section>
          </form>
        </aside>

        {/* Product View */}
        <div className='col-span-12' id='search'>
          {products?.length >= 1 && <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 sm:gap-x-8 md:gap-x-10 gap-y-10">
            {products?.sort((a, b) => a.title.trim().toLowerCase().localeCompare(b.title.toLowerCase().trim())).map((product) => <ProductTeaser key={product?._key} title={product?.title} slug={product?.slug} productImage={product?.productVariations[0]?.productImages && product?.productVariations[0]?.productImages[0]} productCategory={product?.productCategory} greengoodsCategory={product?.greengoodsCategory} />).slice(itemOffset, endOffset)}
            {/* .slice(indexOfFirstProduct, indexOfLastProduct)} */}
          </div>}

          {products?.length <= 0 && (
            <div className="p-4 rounded-md bg-yellow-50">
              <div className="flex">
                <div className="flex-shrink-0">
                  <svg className="w-5 h-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                  </svg>
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-yellow-800">Inventory Alert</h3>
                  <div className="mt-2 text-sm text-yellow-700">
                    <p className="mb-2">Currently there are no products that match that search. Please click the reset button and try another search.</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>


      </div>
      <div className='flex flex-col justify-between pt-8 my-8 border-t border-gray-200 md:flex-row'>
        <ReactPaginate
          breakLabel="..."
          nextLabel="next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="< prev"
          renderOnZeroPageCount={null}
          className="flex flex-wrap w-full text-base text-gray-500"
          pageLinkClassName="mr-3 px-2 pb-2 mb-2 underline decoration-2 decoration-white underline-offset-4 hover:decoration-gray-600 transition-all"
          activeClassName="font-semibold underline decoration-2 decoration-gray-600 underline-offset-4 transition-all text-gray-800"
          previousLinkClassName="mr-3 underline decoration-2 decoration-white underline-offset-4 hover:decoration-gray-600 transition-all"
          nextLinkClassName="underline decoration-2 decoration-white underline-offset-4 hover:decoration-gray-600 transition-all"
          disabledLinkClassName="text-gray-300 hover:no-underline no-underline cursor-not-allowed"
          breakClassName="mr-3 underline decoration-2 decoration-white underline-offset-4 hover:decoration-gray-600 transition-all"
          forcePage={itemOffset / itemsPerPage}
        />

        <div>
          <select
            id="current-tab"
            name="current-tab"
            className="block w-full py-2 pl-3 pr-10 text-base border-gray-300 rounded-md min-w-[145px] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 md:text-sm"
            defaultValue={itemsPerPage}
            onChange={
              value => handleItemsPerPage((parseInt(value?.target?.value)))
            }>
            <option value={48}>48 per page</option>
            <option value={96}>96 per page</option>
            <option value={144}>144 per page</option>
            <option value={192}>192 per page</option>
          </select>
        </div>
      </div>
    </div>
  );
}
