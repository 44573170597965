import Image from 'next/image';
import { urlFor } from '@/lib/sanity'
import { Linker } from '@/components/content/linker'
import { capitalizeFirstLetter } from '@/utils/helpers'


const ImageComponent = ({ image, imageBlur, imageAltText, adjustedHeight, adjustedWidth }) => {
  return (<Image
    src={image}
    alt={imageAltText || ''}
    blurDataURL={imageBlur || ''}
    placeholder={imageBlur ? 'blur' : 'empty'}
    layout="responsive"
    width={adjustedWidth}
    height={adjustedHeight}
    // className="object-cover object-center w-full"
    className='object-cover object-center w-full transition-all duration-500 lg:h-48 md:h-36 hover:scale-110'
  />
  )
}

function FigureLarge(props: { asset?: any; alt?: any; }) {
  const image = props?.asset;
  const { alt } = props;
  // const { aspectRatio } = getImageDimensions(image)
  const adjustedWidth = 425; //918;
  const adjustedHeight = 365; //448;
  // const adjustedHeight = Math.round(adjustedWidth / aspectRatio);

  const imageScaled = (val: any) => {
    return val ? `${urlFor(val).auto('format').width(adjustedWidth).height(adjustedHeight).url()}` : null
  }
  const imageBlur = (val: any) => {
    return val ? `${urlFor(val).auto('format').width(10).url()}` : null
  }

  return <figure><ImageComponent image={imageScaled(image)} imageBlur={imageBlur(image)} imageAltText={alt} adjustedWidth={adjustedWidth} adjustedHeight={adjustedHeight} /></figure>
}

export function ProductTeaser({ title, slug, productImage, productCategory, greengoodsCategory }) {
  return (
    <article className='flex flex-col overflow-hidden rounded-md'>
{/* flex flex-col overflow-hidden rounded-md shadow-md */}
      <div className="relative flex-shrink-0">

        <Linker url={`/products/${slug}`}>
          {productImage ? (
            <FigureLarge {...productImage} />
          ) : (
            <figure className="bg-gradient-to-r from-brand-1-800 to-brand-2-800 pt-[48.78%] w-full relative"></figure>
          )}
        </Linker>

        {productCategory &&
          <div className="absolute px-2 py-1 text-xs font-semibold text-white rounded shadow bg-brand-2-500 top-4 right-4">{capitalizeFirstLetter(productCategory)}
          </div>
        }

      </div>

      <div className="flex flex-col justify-between flex-1 px-4 pt-2 pb-4 bg-white border border-gray-200 rounded-b-lg border-x-1 border-b-1">

        <div className="flex-1"><h3 className="mt-2 mb-1 text-xl font-semibold text-gray-900">
          <Linker url={`/products/${slug}`} classes="hover:underline">{title}</Linker></h3>
        </div>

        {greengoodsCategory &&
          <p className='text-sm text-gray-500'>{capitalizeFirstLetter(greengoodsCategory)}</p>
        }

      </div>
    </article>
  );
}
