import PropTypes from 'prop-types'
import { Text } from '@/components/layout/content-components/serializers/text'
import { CallToAction } from '@/components/content/call-to-action'
import { Tagline } from '@/components/content/tagline'

import { SuperImage } from '@/components/content/super-image'
/*
  Hero Variants
    - side-by-side
    - centered
*/

Hero.propTypes = {
  layout: PropTypes.string,
  tagline: PropTypes.string,
  title: PropTypes.array,
  text: PropTypes.string,
  primaryLinkText: PropTypes.string,
  primaryLinkUrl: PropTypes.string,
  primaryLinkIcon: PropTypes.string,
  primaryLinkType: PropTypes.string,
  secondaryLinkText: PropTypes.string,
  secondaryLinkUrl: PropTypes.string,
  secondaryLinkIcon: PropTypes.string,
  secondaryLinkType: PropTypes.string,
  altText: PropTypes.string,
  fullWidth: PropTypes.bool,
  image: PropTypes.object,
  adjustedHeight: PropTypes.number,
}

export function Hero(props: { layout: string; tagline: string; title: any; text: string; primaryLinkText: string; primaryLinkUrl: string; primaryLinkIcon: string; primaryLinkType: string; secondaryLinkText: string; secondaryLinkUrl: string; secondaryLinkIcon: string; secondaryLinkType: string; altText: string; fullWidth: boolean, image: object;  adjustedHeight: number; }) {

  const { layout, tagline, title, text, primaryLinkText, primaryLinkUrl, primaryLinkIcon, primaryLinkType, secondaryLinkText, secondaryLinkUrl, secondaryLinkIcon, secondaryLinkType, altText, fullWidth, image, adjustedHeight } = props

  const primaryLink = (primaryLinkText && primaryLinkUrl) ? true : false;
  const secondaryLink = (secondaryLinkText && secondaryLinkUrl) ? true : false;
  // console.log({adjustedWidth})
  if (layout === "side-by-side") {
    return (
      <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">

        <div className="flex flex-col grid-cols-12 grid-rows-2 gap-6 overflow-hidden md:grid md:grid-rows-1 lg:gap-6 xl:gap-8">

          {/* Content */}
          <div className="flex order-2 md:order-1 col-span-full md:col-span-6 lg:col-span-5 md:text-left">

            <span>
              {/* Tagline */}
              {tagline && <Tagline text={tagline} size='md' classes="mb-1" theme="light" />}
              {/* Title */}
              {title && <div className="heroTitle">
                <Text blocks={title} />
              </div>}

              {/* Text */}
              {text &&
                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">{text}
                </p>
              }

              {/* Links */}
              {(primaryLink || secondaryLink) &&
                <div className="flex mt-6">
                  <div className="grid items-center gap-6 xl:grid-cols-2">

                    {/* CTA - Primary */}
                    {primaryLink && <CallToAction title={primaryLinkText} url={primaryLinkUrl} type={primaryLinkType} icon={primaryLinkIcon} style={`primary`} size={`lg`} theme={(primaryLinkType === 'textIcon' || primaryLinkType === 'text') ? 'light' : 'dark'} fullWidth={false}/>}

                    {/* CTA - Secondary */}
                    {secondaryLink && <CallToAction title={secondaryLinkText} url={secondaryLinkUrl} type={secondaryLinkType} icon={secondaryLinkIcon} style={`secondary`} size={`lg`} theme={`light`} fullWidth={false}/>}

                  </div>
                </div>}

            </span>
          </div>

          {/* Image */}
          {image &&
            <div className={`relative order-1 overflow-hidden md:order-2 col-span-full md:col-span-6 lg:col-span-7`}>
              <SuperImage image={image} imageAltText={altText || 'Hero Image'} adjustedHeight={adjustedHeight ? adjustedHeight : 520} adjustedWidth={804} fullWidth={false} classes={'rounded-lg'} priority={true} />
            </div>}

        </div>
      </section>
    )
  } else {
    return (
      <section className="mb-8 md:mb-12 lg:mb-16 inner-wrapper">
        <div className={`${fullWidth ? 'full-width' : 'rounded-lg' } relative overflow-hidden`}>

          {/* Image */}
          {image ? <div className="absolute inset-0">
            <div className="relative w-full h-full">
            <SuperImage image={image} imageAltText={altText || 'Hero Image'} adjustedHeight={null} adjustedWidth={1920} fullWidth={true} classes={''} priority={true} />
            </div>

            <div className="absolute inset-0 bg-gradient-to-r from-brand-2-100 to-brand-2-50 mix-blend-multiply" />
          </div> :
            <div className="absolute inset-0">
              <div className="absolute inset-0 bg-gradient-to-r from-brand-2-100 to-brand-2-50 mix-blend-multiply" />
            </div>
          }

          <div className="relative max-w-2xl px-4 py-16 mx-auto sm:px-6 sm:py-24 lg:py-32 lg:px-8">

            {/* Tagline */}
            {tagline && <Tagline text={tagline} size='md' theme="dark" classes="mb-0 text-center" />}

            {/* Title & Subtitle */}
            {title && <div className="text-center heroTitleDark">
              <Text blocks={title} />
            </div>}

            {/* Text */}
            {text &&
              <p className="max-w-lg mx-auto mt-6 text-lg text-center text-gray-100 sm:max-w-3xl md:text-xl lg:text-2xl">{text}
              </p>}

            {/* Links */}
            {(primaryLink || secondaryLink) &&
              <div className="mx-auto mt-12 sm:flex sm:justify-center">
                <div className="flex flex-col items-center justify-center space-y-4 sm:mx-auto md:flex-row md:space-y-0 md:space-x-4 lg:space-x-8">

                  {/* CTA - Primary */}
                  {primaryLink && <CallToAction title={primaryLinkText} url={primaryLinkUrl} type={primaryLinkType} icon={primaryLinkIcon} style={`primary`} size={`lg`} theme={`dark`} fullWidth={false} />}

                  {/* CTA - Secondary */}
                  {secondaryLink && <CallToAction title={secondaryLinkText} url={secondaryLinkUrl} type={secondaryLinkType} icon={secondaryLinkIcon} style={`secondary`} size={`lg`} theme={`dark`} fullWidth={false} />}

                </div>
              </div>}

          </div>
        </div>
      </section>
    )
  }

}
