import React from 'react'
import Image from 'next/image';
import { urlFor } from '@/lib/sanity'

export function ProductCollections({ data, onSetProductCollection }) {

  const ProductCollectionImage = ({ image }) => {
    const imageSource = image?.asset;
    const img = imageSource ? `${urlFor(imageSource).auto('format').width(1440).quality(80).fit('clip').url()}` : 'https://cdn.sanity.io/images/irmayqa7/production/2969f253b0109e888d7156cf0953c6cfd950f2a7-320x275.jpg?w=1440&q=80&fit=clip&auto=format'
    return (
      <Image src={img} alt="product" layout="fill" class="absolute inset-0 object-cover w-full h-full transition-transform duration-500 ease-in-out group-hover:scale-105" />
    )
  }

  const ProductCollectionItem = ({ id, title, subTitle, productImage, onSetProductCollection }) => {
    return (
      <div class="relative flex flex-col h-full overflow-hidden bg-gray-900 rounded-lg group">
        <button onClick={() => onSetProductCollection(id)}>
          <ProductCollectionImage image={productImage} />
          <div class="absolute inset-0 bg-gradient-to-b from-gray-900/50 to-gray-900/5"></div>
          <span class="absolute top-0 left-0 z-10 p-4 text-left">
            {title && <h3 class="text-2xl font-semibold text-white xs:text-xl md:text-2xl">{title}</h3>}
            {subTitle && <p class="z-10 text-white md:text-base">{subTitle}</p>}
          </span>
        </button>
      </div>
    )
  }


  if (!data || data?.length <= 0 || data?.length > 6) return null;

  if (data?.length === 1) {
    return (
      <div className="grid grid-cols-1 grid-rows-1 min-h-[260px] md:min-h-[380px]">
        {data.map((collection, i) => {
          return (
            <ProductCollectionItem key={collection._id} title={collection.title} subTitle={collection.subTitle} productImage={collection.productImage} onSetProductCollection={onSetProductCollection} id={collection._id} />
          )
        })}
      </div>
    )
  } else if (data?.length === 2) {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6 min-h-[320px] md:min-h-[380px]">
        {data.map((collection, i) => {
          return (
            <ProductCollectionItem key={collection._id} title={collection.title} subTitle={collection.subTitle} productImage={collection.productImage} onSetProductCollection={onSetProductCollection} id={collection._id} />
          )
        })}
      </div>
    )
  } else if (data?.length === 3) {
    return (
      <div className="grid md:grid-cols-4 md:grid-rows-2 lg:grid-rows-1 gap-4 md:gap-6 min-h-[380px]">
        {data.map((collection, i) => {
          if(i === 0) {
            return (
              <div className="md:col-span-2 md:row-span-2" key={collection._id}>
              <ProductCollectionItem  title={collection.title} subTitle={collection.subTitle} productImage={collection.productImage} onSetProductCollection={onSetProductCollection} id={collection._id} />
              </div>
            )
          } else if(i === 1){
            return (
              <div className="md:col-start-3 lg:row-span-2 md:col-span-2 lg:col-span-1" key={collection._id}>
              <ProductCollectionItem  title={collection.title} subTitle={collection.subTitle} productImage={collection.productImage} onSetProductCollection={onSetProductCollection} id={collection._id} />
              </div>
            )
          } else if(i === 2){
            return (
              <div className="md:col-span-2 md:col-start-3 md:row-start-2 lg:row-start-1 lg:row-span-2 lg:col-start-4 lg:col-span-1" key={collection._id}>
              <ProductCollectionItem  title={collection.title} subTitle={collection.subTitle} productImage={collection.productImage} onSetProductCollection={onSetProductCollection} id={collection._id} />
              </div>
            )
          }
        })}
      </div>
    )
  } else if(data?.length === 4) {
    return (
      <div className="grid md:grid-cols-4 md:grid-rows-2 gap-4 md:gap-6 min-h-[520px]">
        {data.map((collection, i) => {
          if(i === 0) {
            return (
              <div className="md:col-span-2 md:row-span-2" key={collection._id}>
              <ProductCollectionItem  title={collection.title} subTitle={collection.subTitle} productImage={collection.productImage} onSetProductCollection={onSetProductCollection} id={collection._id} />
              </div>
            )
          } else if(i === 1){
            return (
              <div className="md:col-span-2 md:col-start-3" key={collection._id}>
              <ProductCollectionItem  title={collection.title} subTitle={collection.subTitle} productImage={collection.productImage} onSetProductCollection={onSetProductCollection} id={collection._id} />
              </div>
            )
          } else if(i === 2){
            return (
              <div className="md:col-start-3 md:row-start-2" key={collection._id}>
              <ProductCollectionItem  title={collection.title} subTitle={collection.subTitle} productImage={collection.productImage} onSetProductCollection={onSetProductCollection} id={collection._id} />
              </div>
            )
          } else if(i === 3){
            return (
              <div className="md:col-start-4 md:row-start-2" key={collection._id}>
              <ProductCollectionItem  title={collection.title} subTitle={collection.subTitle} productImage={collection.productImage} onSetProductCollection={onSetProductCollection} id={collection._id} />
              </div>
            )
          }
        })}
      </div>
    )
  } else if(data?.length === 5) {
    return (
      <div className="grid md:grid-cols-4 md:grid-rows-2 gap-4 md:gap-6 min-h-[520px]">
        {data.map((collection, i) => {
          if(i === 0) {
            return (
              <div className="md:col-span-2 md:row-span-2" key={collection._id}>
              <ProductCollectionItem  title={collection.title} subTitle={collection.subTitle} productImage={collection.productImage} onSetProductCollection={onSetProductCollection} id={collection._id} />
              </div>
            )
          } else if(i === 1){
            return (
              <div className="md:col-start-3" key={collection._id}>
              <ProductCollectionItem  title={collection.title} subTitle={collection.subTitle} productImage={collection.productImage} onSetProductCollection={onSetProductCollection} id={collection._id} />
              </div>
            )
          } else if(i === 2){
            return (
              <div className="md:col-start-4" key={collection._id}>
              <ProductCollectionItem  title={collection.title} subTitle={collection.subTitle} productImage={collection.productImage} onSetProductCollection={onSetProductCollection} id={collection._id} />
              </div>
            )
          } else if(i === 3){
            return (
              <div className="md:col-start-3 md:row-start-2" key={collection._id}>
              <ProductCollectionItem  title={collection.title} subTitle={collection.subTitle} productImage={collection.productImage} onSetProductCollection={onSetProductCollection} id={collection._id} />
              </div>
            )
          } else if(i === 4){
            return (
              <div className="md:col-start-4 md:row-start-2" key={collection._id}>
              <ProductCollectionItem  title={collection.title} subTitle={collection.subTitle} productImage={collection.productImage} onSetProductCollection={onSetProductCollection} id={collection._id} />
              </div>
            )
          }
        })}
      </div>
    )
  } else if(data?.length === 6) {
    return (
      <div className="grid md:grid-cols-4 md:grid-rows-2 gap-4 md:gap-6 min-h-[520px]">
        {data.map((collection, i) => {
          if(i === 0) {
            return (
              <div className="md:col-span-2" key={collection._id}>
              <ProductCollectionItem  title={collection.title} subTitle={collection.subTitle} productImage={collection.productImage} onSetProductCollection={onSetProductCollection} id={collection._id} />
              </div>
            )
          } else if(i === 1){
            return (
              <div className="md:col-span-2 md:col-start-1 md:row-start-2" key={collection._id}>
              <ProductCollectionItem  title={collection.title} subTitle={collection.subTitle} productImage={collection.productImage} onSetProductCollection={onSetProductCollection} id={collection._id} />
              </div>
            )
          } else if(i === 2){
            return (
              <div className="md:col-start-3 md:row-start-1" key={collection._id}>
              <ProductCollectionItem  title={collection.title} subTitle={collection.subTitle} productImage={collection.productImage} onSetProductCollection={onSetProductCollection} id={collection._id} />
              </div>
            )
          } else if(i === 3){
            return (
              <div className="md:col-start-4 md:row-start-1" key={collection._id}>
              <ProductCollectionItem  title={collection.title} subTitle={collection.subTitle} productImage={collection.productImage} onSetProductCollection={onSetProductCollection} id={collection._id} />
              </div>
            )
          } else if(i === 4){
            return (
              <div className="md:col-start-3 md:row-start-2" key={collection._id}>
              <ProductCollectionItem  title={collection.title} subTitle={collection.subTitle} productImage={collection.productImage} onSetProductCollection={onSetProductCollection} id={collection._id} />
              </div>
            )
          } else if(i === 5){
            return (
              <div className="md:col-start-4 md:row-start-2" key={collection._id}>
              <ProductCollectionItem  title={collection.title} subTitle={collection.subTitle} productImage={collection.productImage} onSetProductCollection={onSetProductCollection} id={collection._id} />
              </div>
            )
          }
        })}
      </div>
    )
  } else {
    return null;
  }
}
